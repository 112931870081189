
.discuss-container {
    height: calc(100% - 40px);
    background: #F2F2F2;
    padding: 20px 0;
    .discuss-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 1200px;
        margin: 0 auto;
    }
}
::v-deep .el-breadcrumb {
    margin-bottom: 20px;
    .el-breadcrumb__inner {
        color: #000;
    }
}
.discuss-list {
    display: flex;
    flex: 1;
    height: 1%;
    flex-direction: column;
    background-color: #fff;
    .detail-title {
        cursor: pointer;
    }
}
.pages-center {
    margin-bottom: 20px;
}
